














































import Display from '@/utilities/Display'
import InsideContainer from '@/components/Containers/InsideContainer.vue'
import HomeActions from '@/components/Dashboards/HomeActions.vue'
import RecentActions from '@/components/Dashboards/RecentActions.vue'

export default {
  name: 'Home',
  components: {
    InsideContainer,
    HomeActions,
    RecentActions,
  },
  computed: {
    mobile(): boolean { return Display.mobile(this.$vuetify) },
  },
}
