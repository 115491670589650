


























export default {
  name: 'HomeActions',
  data() {
    return {
      selectedItem: 0,
      items: [
        {
          text: 'My Photobooks',
          icon: 'mdi-book-open-variant',
          color: 'red',
          path: '/list/books',
        },
        {
          text: 'Upload Photos',
          icon: 'mdi-plus-box-multiple',
          color: 'green',
          path: '/addphotos',
        },
        {
          text: 'My Photos',
          icon: 'mdi-camera',
          color: 'red',
          path: '/list/uploads',
        },
        {
          text: 'Friends Photobooks',
          icon: 'mdi-account-group',
          color: 'red',
          path: '',
        },
        {
          text: 'Tags',
          icon: 'mdi-tag',
          color: 'red',
          path: '',
        },
        {
          text: 'Offers',
          icon: 'mdi-cash-usd',
          color: 'red',
          path: '',
        },
      ],
    }
  },
  methods: {
  },
}
