















export default {
  name: 'RecentActions',
  data() {
    return {
    }
  },
  methods: {
  },
}
